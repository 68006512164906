@import "./base.scss";
@import "./custom.scss";

/**========================================================================
 *                           Layout
 *========================================================================**/
.container {
  max-width: "100%"; //This will make container to take screen width
}

$assetImage: "../images";

body {
  background: #0f0f0f;
  font-size: 100%;
}

/**======================
*    According
*========================**/

.accordion-button.collapsed {
  background: $composition8;
  color: $composition1;
  transition: all 1s ease-in-out;
}

.accordion-button.open {
  background: $composition7;
  color: $composition2;
  box-shadow: none;
  border-style: none;
  transition: all 0.5s ease-in-out;
}

.accordion-body {
  box-shadow: none;
  transition: all 1s ease-in-out;
  // border-style: $border-style;
}

.accordion-button {
  box-shadow: $shadow;
  border-style: $border-style;
  transition: all 1s ease-in-out;
}

// .accordion-button.active {
//   color: red;
// }
// .accordion-header {
//   color: red;
// }

.accordion-button:focus {
  z-index: 3;
  border-color: $hightlight-color;
  background: $composition9;
  outline: 0;
  box-shadow: $shadow;
  transition: all 1s ease-in-out;
}

.accordion-button:not(.collapsed) {
  color: $composition0;
  background-color: $composition9;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  transition: all 1s ease-in-out;
}

/**========================================================================
    *                           Layout
    *========================================================================**/

/**============================================
    *               Header
    *=============================================**/
header {
  z-index: 0;
  // width: 100%;
  background: #161415 url(../public/images/background.png) no-repeat top center;
  background-size: cover !important;

  #banner {
    z-index: 1;
    text-shadow: $text_shadow;
    display: inline;
    justify-content: center;

    .banner {
      border: rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.4);
      box-shadow: 0.6em 0.6em 1em $composition0;
      border-style: $border-style;
    }

    h1 {
      font: bold 4em/1em $heading1;
      color: $composition9;
      text-align: center;
    }

    h4 {
      font: $heading2;
      color: $composition9;

      span,
      a {
        color: $composition9;
      }
    }

    #keyword {
      color: $composition9;
      font-size: 1rem;
      text-align: center;
      transition: all 1s ease-in-out;
      &:hover {
        color: $hightlight-color;
        transform: scale(1.1);
      }
    }
  }
}

#social {
  font-size: 1.5rem;
  text-shadow: $text_shadow;

  a {
    display: inline-flex;
    justify-content: center;
    color: $composition8;
    text-decoration: none;
    transition: all 0.5s ease-in-out;

    &:focus,
    &:hover {
      color: $hightlight-color;
      transform: scale(1.25);
    }
  }
}

/**============================================
    *               Combined Sections
    *=============================================**/
#stack {
  background: #161415 url(../public/images/prism.png) repeat;
}

#about,
#resume,
#gallery,
#decor {
  border: 0.2em solid $composition8;
  box-shadow: 0.5em 0.5em 1em $composition0;
  border-style: $border-style;
  background: #161415 url(../public/images/dark-honeycomb.png) repeat;
  background-attachment: fixed;

  h1 {
    font: bold 2em/2em $heading1;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.9);

    span {
      border-bottom: 3px solid $composition2;
      border-bottom-style: groove;
    }
  }

  h2 {
    font: bold 2em/2em $heading2;
  }

  h3 {
    font: 25px/30px $heading1;
  }

  h4 {
    font: 20px/25px $heading2;
  }

  h5 {
    font: italic 16px/20px $heading1;
  }

  p {
    color: $composition2;
    line-height: 2em;
    font: $bodytext;
  }
}

/**============================================
 *               About
 *=============================================**/

#about {
  background: $composition5;
  background-image: radial-gradient($composition7, $composition5);

  h2 {
    color: $composition9;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  }

  p {
    color: $composition8;
  }

  .profile-pic {
    @include standardbox;
  }

  .bio {
    @include standardbox;
    background: $composition2;
  }
}

/**============================================
 *               Resume
 *=============================================**/

#resume {
  #education,
  #work,
  #craft {
    background-color: $composition4;
    background-image: radial-gradient($composition5, $composition4);
    border: 1px solid $composition8;
    @include standardbox;
  }

  .barskill {
    width: 100%;
    background-image: radial-gradient($composition5, $composition4);
    box-sizing: border-box;
    padding-left: 0px;
    box-shadow: 1px 1px 2px $composition0;
    border: 1px solid $composition1;
  }

  .skills {
    text-align: right;
    // padding-top: 8px;
    // padding-bottom: 8px;
    margin-left: 0px;
    color: $composition1;
    transition: 1s ease;
    transition-delay: 1s;
  }

  .slabel {
    color: $composition9;
    font: $bodytext;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  }
}

/**============================================
 *               Footer
 *=============================================**/

footer {
  z-index: 5;
  background: $composition1;
  background-image: linear-gradient($composition1, $composition0);
  color: $composition6;
  text-align: center;
  position: fixed;
  bottom: 0;

  a,
  a:visited {
    color: $composition3;
  }

  .copyright {
    display: flex;
    justify-content: center;
    color: $composition5;

    &:before {
      content: "\2022";
    }

    &:first-child:before {
      display: none;
    }
  }
}
